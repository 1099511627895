import React from "react";
import {useTranslation} from "react-i18next";
// import Particless from "../Particless/Particless";

function Opinion1() {
  const { t } = useTranslation();
  return (
    <div className="opinion">
      <div className="op_header">
      <h3> {t("opinion")} </h3>
      </div>
      <div className="op_three">
        <div className="firstpath">
          <img src="/images/marduk.svg" alt="WEBSOFT IT Company"></img>
          <p className="name">
            {t("opinion2-name")}
          </p>
          <p className="info">
           {t("pinion2-text")}
            </p>
        </div>
        <div className="bar bar2 left"> 
          <p>
          {t("opinion2-info")}
          </p>
        </div>
        <div className="op_vector">
          <img src="/images/vector.svg" alt="#"></img>
          {/* <Particless></Particless> */}
        </div>
      </div>
    </div>
  );
}

export default Opinion1;
