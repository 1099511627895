import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
// import * as yup from "yup";

// import { userSchema } from "../Validations/UserValidation";

function Feedback() {
  const form = useRef();
  const { t } = useTranslation();
  const [state, setState] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();
    const formData = {
      user_name: e.target[0].value,
      user_email: e.target[1].value,
      to_name: "WebSoft",
      number: e.target[2].value,
      message: e.target[3].value,
    };

    emailjs
      .send("service_wa7zpyl", "template_1rce192", formData, {
        publicKey: "VX34OgjI1gTFm_Z7D",
      })
      .then(
        () => {
          // console.log("SUCCESS!");
          setRegistrationSuccess(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset();
  };
  

  return (
    <div className="feed_all" id="feedback">
      <div className="h3">
        <h3>{t("feedback")}</h3>
      </div>

      <div className="feedback">
        <div className="left-line"></div>
        <div className="feed_image">
          <img src="/images/feedback.gif" alt="WebSoft_Feedback"></img>
        </div>
        <div className="right-line"></div>

        <form ref={form} onSubmit={sendEmail} className="contact">
          <div className="contact_inputs">
            <input
              onChange={handleChange}
              name="user_name"
              className="input"
              type="text"
              required
              placeholder={t("anazg1")}
            />
            <input
              onChange={handleChange}
              name="user_email"
              className="input"
              type="email"
              required
              placeholder={t("mail")}
            />
            <input
              onChange={handleChange}
              name="number"
              className="input"
              type="number"
              required
              placeholder={t("number")}
            />
            <input
              className="input"
              onChange={handleChange}
              name="message"
              type="text"
              required
              placeholder={t("message")}
            />
            {registrationSuccess ? (
              <p className="successChange">{t("thanks")}<br/>{t("messagesent")}</p>
            ) : null }
          </div>

          <input
            type="submit"
            name=""
            value={t("contsend")}
            className="input_btn dblockbtn"
          />
          <input
            type="submit"
            value={t("contsend")}
            className="input_btn  dnonebtn"
          />
        </form>
      </div>
    </div>
  );
}

export default Feedback;
