import React from "react";
import {useTranslation} from "react-i18next"
import vector1 from "../../img/icons-1.jpg"
import vector2 from "../../img/icons-2.jpg"
import vector3 from "../../img/icons-3.jpg"


function Workstyle() {
    const { t } = useTranslation();

    return (
        <div className="workstyle">

            <div className="workstyle-blok">

                {/* <div className="work-left-line"></div> */}
                    <div className="icon-blok">
                        <img className="workstyle-icon " alt="Թիրախավորված լուծումներ" src={vector1}></img>
                        <p className="workstyle-info">{t("work")}</p>
                    </div>
                {/* <div className="work-top-line"></div> */}
            </div>

            
            <div className="workstyle-blok">
                {/* <div className="work-left-line"></div> */}
                <div className="icon-blok">
                    <img className="workstyle-icon" alt="Ժամանակի խնայողություն" src={vector2}></img>
                    <p className="workstyle-info workstyle-info-1">{t("work1")}</p>
                        
                    </div>
                {/* <div className="work-top-line"></div> */}
            </div>
            <div className="workstyle-blok">
                {/* <div className="work-left-line"></div> */}
                <div className="icon-blok">
                    <img className="workstyle-icon" alt="Անխափան համագործակցություն" src={vector3 }/>
                    <p className="workstyle-info">{t("work2")}</p>
                        
                </div>

                {/* <div className="work-top-line"></div> */}
            </div>
        </div>
    )
}

export default Workstyle;